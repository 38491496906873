<template>
  <div>
    <div class="div-input">
      <div class="display-only" v-if="isDisplay"></div>
      <div :class="['input-custom', { error: isValidate }]">
        <div class="d-flex justify-content-between">
          <label v-if="textFloat" :class="labelClass">
            {{ textFloat }}
            <span v-if="isRequired" class="text-danger">*</span>
          </label>
        </div>
      </div>
    </div>
    <div class="border-preview">
      <div class="my-3 d-flex justify-content-center" style="column-gap: 1rem">
        <div class="wrap-panel" v-if="isBanner">
          <div
            class="p-2 layout"
            v-bind:style="{ backgroundImage: 'url(' + bgImage + ')' }"
          >
            <template>
              <div class="my-auto w-100 text-center">
                <div class="card-preview">
                  <div
                    class="image-preview"
                    :style="{
                      '--imageBanner': 'url(' + image + ')',
                      '--contentBanner': `'${this.contentImage}'`,
                    }"
                  ></div>
                  <div class="p-3">
                    <b-button variant="custom-primary" block>{{
                      text || "Button Label"
                    }}</b-button>
                    <div class="skel"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <template v-else>
          <div class="wrap-panel" style="--textPreview: 'Card Preview'">
            <div
              class="p-2 layout"
              v-bind:style="{ backgroundImage: 'url(' + bgImage + ')' }"
            >
              <template>
                <div class="my-auto w-100 text-center">
                  <div
                    class="card-preview mx-auto card-overlay"
                    style="width: 80%"
                  >
                    <div class="image-preview-2"></div>
                    <div class="p-3">
                      <b-row no-gutters style="row-gap: 0.5rem">
                        <template v-for="n in 5">
                          <b-col cols="4" class="pr-2" :key="'b1' + n">
                            <div class="skel-2"></div>
                          </b-col>
                          <b-col cols="8" :key="'b2' + n">
                            <div class="skel-2"></div>
                          </b-col>
                        </template>
                      </b-row>
                      <div class="content-between mt-2">
                        <div style="width: 25%">
                          <div class="skel-2" style="height: 25px"></div>
                        </div>
                        <div style="width: 10%">
                          <div class="skel-2" style="height: 25px"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-preview mx-auto card-overlay-3"
                    style="width: 80%"
                  >
                    <div class="image-preview-2"></div>
                    <div class="p-3">
                      <b-row no-gutters style="row-gap: 0.5rem">
                        <template v-for="n in 5">
                          <b-col cols="4" class="pr-2" :key="'s1' + n">
                            <div class="skel-2"></div>
                          </b-col>
                          <b-col cols="8" :key="'s2' + n">
                            <div class="skel-2"></div>
                          </b-col>
                        </template>
                      </b-row>
                      <div class="content-between mt-2">
                        <div style="width: 25%">
                          <div class="skel-2" style="height: 20px"></div>
                        </div>
                        <div style="width: 10%">
                          <div class="skel-2" style="height: 20px"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-preview mx-auto card-overlay-2"
                    style="width: 80%"
                  >
                    <div class="image-preview-2"></div>
                    <div class="p-3">
                      <b-row no-gutters style="row-gap: 0.5rem">
                        <template v-for="n in 5">
                          <b-col cols="4" class="pr-2" :key="'a1' + n">
                            <div class="skel-2"></div>
                          </b-col>
                          <b-col cols="8" :key="'a2' + n">
                            <div class="skel-2"></div>
                          </b-col>
                        </template>
                      </b-row>
                      <div class="content-between mt-2">
                        <div style="width: 25%">
                          <div class="skel-2" style="height: 20px"></div>
                        </div>
                        <div style="width: 10%">
                          <div class="skel-2" style="height: 20px"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="wrap-panel" style="--textPreview: 'List Preview'">
            <div
              class="layout p-2"
              v-bind:style="{ backgroundImage: 'url(' + bgImage + ')' }"
            >
              <template>
                <div class="my-auto w-100 text-center">
                  <div
                    class="card-preview mb-3"
                    v-for="card in 5"
                    :key="'card' + card"
                  >
                    <b-row no-gutters>
                      <b-col cols="4"
                        ><div class="image-preview-2"></div>
                      </b-col>
                      <b-col cols="8">
                        <div class="p-2">
                          <b-row no-gutters style="row-gap: 0.5rem">
                            <template v-for="n in 3">
                              <b-col cols="4" class="pr-2" :key="'as1' + n">
                                <div class="skel-2"></div>
                              </b-col>
                              <b-col cols="8" :key="'as2' + n">
                                <div class="skel-2"></div>
                              </b-col>
                            </template>
                          </b-row>
                          <div class="content-between mt-2">
                            <div class="col-4 pr-2 pl-0">
                              <div class="skel-2" style="height: 20px"></div>
                            </div>
                            <div style="width: 10%">
                              <div class="skel-2" style="height: 20px"></div>
                            </div>
                          </div>
                        </div> </b-col
                    ></b-row>
                  </div>

                  <!-- <div
                      class="image-preview"
                      :style="{
                        '--imageBanner': 'url(' + image + ')',
                        '--contentBanner': `'${this.contentImage}'`,
                      }"
                    ></div>
                    <div class="p-3">
                      <b-button variant="secondary" block  disabled>{{
                        text || "Button Label"
                      }}</b-button>
                      <div class="skel"></div>
                    </div> -->
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import imgDefault from "@/assets/img/default-placeholder.png";

export default {
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    labelClass: {
      required: false,
      type: String,
      default: "",
    },
    text: {
      required: false,
      type: String,
    },
    bgImage: {
      required: false,
      type: String,
    },
    image: {
      required: false,
      type: String,
    },

    isBanner: { required: false },
  },
  data() {
    return {
      imgDefault: imgDefault,
      contentImage: "UPLOAD BANNER",
    };
  },
  watch: {
    image(val) {
      if (val) {
        this.contentImage = "";
      }
    },
  },
  created() {
    if (this.image) {
      this.contentImage = "";
    }
  },
};
</script>

<style lang="scss" scoped>
.div-input {
  margin-bottom: 0px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}
.input-custom label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}
.input-custom textarea {
  color: var(--font-main-color);
  background-color: white;
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
}
.input-custom textarea:focus {
  border: 1px solid #16274a;
}
.input-custom.error textarea {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: 0.8rem;
  font-family: "Kanit-Light";
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
.count-characters {
  color: #9b9b9b;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 0px;
}
@media (max-width: 767.98px) {
  .input-custom label {
    font-size: 15px;
  }
}
.border-preview {
  border: 2px dashed #979797;
  padding: 2rem;
}
.wrap-panel {
  position: relative;
  width: 250px;
}
.layout {
  width: 100%;
  border-radius: 0.75rem;
  position: relative;
  overflow: hidden;
  height: 100%;
  aspect-ratio: 9 / 19.5;
  // background-image: url(../../../../assets/img/fgf/1.png);
  background: gray;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: space-between; */
}
.card {
  border-radius: 0.75rem !important;
  border-color: white !important;
}

.options-detail {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  color: var(--primary-color);
}
.box-link {
  border-radius: 0.5rem;
  border: 1px dashed var(--primary-color);
  padding: 0.5rem 1rem;
  background-color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.badge-count {
  padding: 0.25rem;
  font-size: 75%;
  margin-top: 0.25rem;
  width: 50px;
  text-align: center;
  height: auto;
  /* aspect-ratio: 1; */
  margin-left: 0.5rem;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  border-radius: 0.75rem;
}

.opacity {
  opacity: 0.5;
}
.navbar {
  height: 80px;
  width: 100%;
  justify-content: center;
  background-color: var(--theme-navigation-bar-color);
  transition: background-color 0.1s linear;
  box-shadow: 1px 1px 5px -3px black;
}
.logo-img {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 65px;
}
::v-deep .description-page {
  color: #5f5f5f;
  font-size: 14px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  p {
    margin-bottom: 0;
  }
}
.card-preview {
  border-radius: 0.25rem;
  background-color: white;
  overflow: hidden;
  // padding: 1rem;
}
.image-preview {
  height: auto;
  aspect-ratio: 1;
  z-index: 2;
  width: 100%;
  position: relative;
  background-color: rgba(128, 128, 128, 0.33);
  background-image: var(--imageBanner);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.image-preview::after {
  content: var(--contentBanner);
  // background: url("https://api-hourglass-admin-dev.dosetech.co/media/NewsImage/234925044917713.png");
  position: absolute;
  top: 38%;
  z-index: 1;

  transform: translate(-50%);
  left: 50%;
  letter-spacing: 1.5px;
  font-size: 38px;
  color: #acacac;
}
.image-preview-2 {
  height: 100%;
  aspect-ratio: 1;
  z-index: 2;
  width: 100%;
  position: relative;
  background-color: rgba(128, 128, 128, 0.33);
}
.text-bold {
  font-weight: bold;
}
.skel {
  width: 80px;
  height: 10px;
  background-color: rgba(128, 128, 128, 0.33);
  margin: 10px auto;
}
.text-color {
  color: var(--primary-color);
}
.card-overlay {
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%) translateX(-100%);
}
.card-overlay-2 {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) translateX(95%);
}
.card-overlay-3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}
.skel-2 {
  width: 100%;
  height: 10px;
  background-color: rgba(128, 128, 128, 0.33);
  border-radius: 0.5rem;
}
.list-view {
}
.wrap-panel::before {
  content: var(--textPreview);
  font-size: 16px;
  color: black;
  top: -5%;
  position: absolute;
}
</style>
