<template>
  <div class="p-3">
    <b-row no-gutters>
      <b-col md="5">
        <b-input-group>
          <b-form-input
            type="text"
            id="header-search-bar"
            class="search-bar"
            placeholder="Search Customer Name, Tel."
            v-model="filter.search"
            v-debounce:500ms="submitFilter"
            @keyup.enter="submitFilter"
          >
          </b-form-input>
          <b-input-group-append is-text class="mr-1">
            <b-iconstack font-scale="2" @click.prevent="submitFilter">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col md="7" class="text-right">
        <b-button
          v-b-toggle.sidebar-filter
          class="btn button btn-mobile ml-1"
          id="filterBtn"
        >
          <font-awesome-icon
            icon="filter"
            title="filter-btn"
            class="main-color mr-0 mr-sm-1 pointer"
          />
          <span class="d-none d-sm-inline">Filter </span>
        </b-button>
      </b-col>

      <b-table
        class="mt-2"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(customerDetail)="data">
          <div class="pl-2 text-left">
            <router-link
              :to="`/report/customer/${data.item.user_guid}`"
              class="text-purple-1"
            >
              <div>{{ data.item.first_name }} {{ data.item.last_name }}</div>
            </router-link>

            <p class="mb-0 text-gray">{{ data.item.telephone }}</p>
            <p class="mb-0 text-gray">{{ data.item.email }}</p>
          </div>
        </template>
        <template v-slot:cell(customField1)="data">
          <div>
            <a
              v-if="data.item.customField1Type == 8"
              :href="data.item.customField1"
              target="_blank"
            >
              <div
                class="table-image"
                v-bind:style="{
                  'background-image': 'url(' + data.item.customField1 + ')',
                }"
              ></div>
            </a>
            <div v-else-if="data.item.customField1Type == 7">
              {{ data.item.customField1 | moment($formatDate) }}
            </div>
            <div v-else-if="data.item.customField1Type == 5">
              {{ data.item.customField4 | moment($formatDateTime) }}
            </div>
            <div v-else>{{ data.item.customField1 }}</div>
          </div>
        </template>
        <template v-slot:cell(customField2)="data">
          <div>
            <a
              v-if="data.item.customField2Type == 8"
              :href="data.item.customField2"
              target="_blank"
            >
              <div
                class="table-image"
                v-bind:style="{
                  'background-image': 'url(' + data.item.customField2 + ')',
                }"
              ></div>
            </a>
            <div v-else-if="data.item.customField2Type == 7">
              {{ data.item.customField2 | moment($formatDate) }}
            </div>
            <div v-else-if="data.item.customField4Type == 5">
              {{ data.item.customField2 | moment($formatDateTime) }}
            </div>
            <div v-else>{{ data.item.customField2 }}</div>
          </div>
        </template>
        <template v-slot:cell(customField3)="data">
          <div>
            <a
              v-if="data.item.customField3Type == 8"
              :href="data.item.customField3"
              target="_blank"
            >
              <div
                class="table-image"
                v-bind:style="{
                  'background-image': 'url(' + data.item.customField3 + ')',
                }"
              ></div>
            </a>
            <div v-else-if="data.item.customField3Type == 7">
              {{ data.item.customField3 | moment($formatDate) }}
            </div>
            <div v-else-if="data.item.customField3Type == 5">
              {{ data.item.customField3 | moment($formatDateTime) }}
            </div>
            <div v-else>{{ data.item.customField3 }}</div>
          </div>
        </template>
        <template v-slot:cell(customField4)="data">
          <div>
            <a
              v-if="data.item.customField4Type == 8"
              :href="data.item.customField4"
              target="_blank"
            >
              <div
                class="table-image"
                v-bind:style="{
                  'background-image': 'url(' + data.item.customField4 + ')',
                }"
              ></div>
            </a>
            <div v-else-if="data.item.customField4Type == 7">
              {{ data.item.customField4 | moment($formatDate) }}
            </div>
            <div v-else-if="data.item.customField4Type == 5">
              {{ data.item.customField4 | moment($formatDateTime) }}
            </div>
            <div v-else>{{ data.item.customField4 }}</div>
          </div>
        </template>
        <template v-slot:cell(customField5)="data">
          <div>
            <a
              v-if="data.item.customField5Type == 8"
              :href="data.item.customField5"
              target="_blank"
            >
              <div
                class="table-image"
                v-bind:style="{
                  'background-image': 'url(' + data.item.customField5 + ')',
                }"
              ></div>
            </a>
            <div v-else-if="data.item.customField5Type == 7">
              {{ data.item.customField5 | moment($formatDate) }}
            </div>
            <div v-else-if="data.item.customField5Type == 5">
              {{ data.item.customField5 | moment($formatDateTime) }}
            </div>
            <div v-else>{{ data.item.customField5 }}</div>
          </div>
        </template>
        <template v-slot:cell(customField6)="data">
          <div>
            <a
              v-if="data.item.customField6Type == 8"
              :href="data.item.customField6"
              target="_blank"
            >
              <div
                class="table-image"
                v-bind:style="{
                  'background-image': 'url(' + data.item.customField6 + ')',
                }"
              ></div>
            </a>
            <div v-else-if="data.item.customField6Type == 7">
              {{ data.item.customField6 | moment($formatDate) }}
            </div>
            <div v-else-if="data.item.customField6Type == 5">
              {{ data.item.customField6 | moment($formatDateTime) }}
            </div>
            <div v-else>{{ data.item.customField6 }}</div>
          </div>
        </template>
        <template v-slot:cell(created_time)="data">
          <div class="d-flex justify-content-center">
            {{
              $moment(data.item.created_time).format("DD/MM/YYYY hh:mm:ss A")
            }}
          </div>
        </template>

        <template v-slot:cell(action)="data">
          <b-button variant="custom-link">
            <font-awesome-icon
              icon="trash-alt"
              class="ft-14 ml-2"
              @click="deleteAnswer(data.item.answer_id)"
            />
          </b-button>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>

      <Pagination
        class="px-3 pb-3"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
        @handleChangeTake="handleChangeTake"
      />
    </b-row>
    <b-sidebar
      id="sidebar-filter"
      v-model="sidebarShow"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      ref="filterSidebar"
      aria-labelledby="sidebar-filter-title"
      no-header
      width="80vw"
    >
      <template>
        <div class="px-3">
          <b-row class="header-filter">
            <b-col class="text-filter"> Filter </b-col>
            <b-col class="text-right">
              <button class="btn-clear" @click="clearFilter">
                x <span class="text-under">Clear Filter</span>
              </button>
            </b-col>
          </b-row>
          <div>
            <div class="my-2">
              <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
              <b-form-input
                type="text"
                placeholder="Search Customer Name, Tel."
                class="input-container search-filter"
                v-model="filter.search"
                @keypress.enter="submitFilter"
              ></b-form-input>
            </div>

            <div class="mt-2">
              <div class="font-weight-bold mb-2">Date From</div>
              <div class="input-container">
                <datetime
                  id="date"
                  v-model="filter.date_from"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  value-zone="Asia/Bangkok"
                  :max-datetime="filter.date_to || dateFormat()"
                  ref="dateFrom"
                >
                </datetime>
                <div
                  id="startDateIcon"
                  class="icon-primary text-right"
                  @click="$refs.dateFrom.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer color-primary"
                    color="var(--primary-color)"
                  />
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="font-weight-bold mb-2">Date To</div>
              <div class="input-container">
                <datetime
                  id="date"
                  v-model="filter.date_to"
                  placeholder="DD/MM/YYYY"
                  :min-datetime="filter.date_from"
                  :max-datetime="dateFormat()"
                  format="dd/MM/yyyy"
                  value-zone="Asia/Bangkok"
                  ref="dataTo"
                >
                </datetime>
                <div
                  id="startDateIcon"
                  class="icon-primary text-right"
                  @click="$refs.dataTo.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer color-primary"
                    color="var(--primary-color)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light"
              @click.prevent="sidebarShow = false"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              @click.prevent="submitFilter"
              block
              variant="light"
              id="handle-search-filter"
              class="text-body btn-main"
            >
              Search
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        custom_object_id: this.$route.params.id, // must have na !!!
        search: "",
        user_guid: "", // หน้า customer detail ใส่ user_guid มาด้วยนะ
        date_from: "",
        date_to: "",
        page: 1,
        page_size: 10,
        take: 10,
      },
      fields: [
        {
          key: "customerDetail",
          label: "Customer Detail",
        },
        {
          key: "customField1",
          label: "",
        },
        {
          key: "customField2",
          label: "",
        },
        {
          key: "customField3",
          label: "",
        },
        {
          key: "customField4",
          label: "",
        },
        {
          key: "customField5",
          label: "",
        },
        {
          key: "customField6",
          label: "",
        },
        {
          key: "created_time",
          label: "Answer Date",

          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      sidebarShow: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isBusy = true;
      const resp = await this.$Axios.post(
        `${process.env.VUE_APP_API}/CustomObject/answer_by_custom_object_id`,
        this.filter
      );

      if (resp.data.detail.answer_datas) {
        var i = 1;
        let name = resp.data.detail.answer_datas[0].datas.map(
          (el) => el.field_name
        );

        for (const field of name) {
          var f = this.fields.find((el) => el.key == `customField${i}`);

          // if (f) {
          f.label = field;
          // }
          i++;
        }

        var n = 0;

        for (var ans of resp.data.detail.answer_datas) {
          var temp = {};
          for (const ansData of ans.datas) {
            let key = this.fields.find((el) => el.label == ansData.field_name);

            if (key) {
              temp[key.key] = ansData.answer_detail
                .map((el) => el.value_answer)
                .join(",");
              temp[`${key.key}Type`] = ansData.field_type_id;
            }
          }

          // console.log(ans, temp);
          resp.data.detail.answer_datas[n] = { ...ans, ...temp };
          n++;
        }

        this.items = resp.data.detail.answer_datas;
        this.rows = resp.data.detail.total;
        this.isBusy = false;
        // }, 300);
      } else {
        this.items = [];
        this.rows = 0;
        this.isBusy = false;
      }
    },
    submitFilter() {
      this.sidebarShow = false;
      this.getData();
    },
    openModal() {},
    pagination(value) {
      this.filter.page = value;
      this.getData();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.filter.page_size = value;
      this.getData();
    },
    clearFilter() {
      this.filter = {
        custom_object_id: this.$route.params.id, // must have na !!!
        search: "",
        user_guid: "", // หน้า customer detail ใส่ user_guid มาด้วยนะ
        date_from: "",
        date_to: "",
        page: 1,
        page_size: 10,
        take: 10,
      };
    },
    async deleteAnswer(id) {
      const resp = await this.$Axios.delete(
        `${process.env.VUE_APP_API}/CustomObject/delete_answer/${id}`
      );
      if (resp.data.result) {
        this.successAlert().then(() => this.getData());
      } else {
        this.errorAlert(resp.data.message);
      }
    },
    dateFormat() {
      return this.$moment().format();
    },
  },
};
</script>

<style scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.btn-clear {
  background-color: transparent;
  border: none;
}

.btn-main {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.btn {
  border-radius: 0;
}
</style>
